import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../common/components/Loader';
import { useVerifyEmailMutation } from './auth.api';
import routes from '../common/routing/routes';
import Animate from '../common/components/Animate';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [verificationStatus, setVerificationStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [verifyUserEmail, { isLoading }] = useVerifyEmailMutation();

  useEffect(() => {
    async function verifyEmail() {
      if (!token) {
        toast.error('Your token is invalid');
        setVerificationStatus('error');
      } else {
        toast.info('Verifying your email...');
        await verifyUserEmail({ token })
          .unwrap()
          .then(() => {
            toast.success('Your email was successfully verified');
            setVerificationStatus('success');
          })
          .catch(() => {
            toast.error('Failed to verify your email');
            setVerificationStatus('error');
          });
      }
    }

    verifyEmail();
  }, [token]); // Empty dependency array to run the effect only once

  return (
    <main>
      <Loader show={isLoading} />
      <Animate>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={routes.Login.absolutePath} className="text-dark">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to login
              </Card.Link>
            </p>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white border rounded border-light p-4 p-lg-5 w-lg-50 w-md-100">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Verify Your Email</h3>
                  </div>
                  {verificationStatus === 'verifying' && (
                    <p className="text-center">Please wait while we verify your email...</p>
                  )}
                  {verificationStatus === 'success' && (
                    <div className="text-center">
                      <p className="text-center text-success">Your email has been successfully verified!</p>
                      <Button variant="link" href={routes.Login.absolutePath}>
                        Go to Login
                      </Button>
                    </div>
                  )}
                  {verificationStatus === 'error' && (
                    <p className="text-center text-danger">There was an error verifying your email. Please try again.</p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Animate>
    </main>
  );
};

export default VerifyEmail;
