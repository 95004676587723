import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Animate from '../common/components/Animate';
import routes from '../common/routing/routes';
import { useGetSingleUserQuery } from '../users/users.api';
import { useSendVerificationEmailMutation } from './auth.api';
import Loader from '../common/components/Loader';

const SendVerificationEmail = () => {
  const [sent, setSent] = useState(false);
  const [sendVerificationEmail, { isLoading: sending }] = useSendVerificationEmailMutation();
  const [timer, setTimer] = useState(0);
  const location = useLocation();
  const userId = sessionStorage.getItem('userId') || localStorage.getItem('userId') || '';
  const { data: user, refetch } = useGetSingleUserQuery({ id: userId }, { skip: !userId });

  if (!userId) {
    return <Navigate to={routes.Login.absolutePath} state={{ from: location }} replace />;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await sendVerificationEmail()
      .unwrap()
      .then(() => {
        toast.success('Verification email sent successfully');
        setSent(true);
        setTimer(60); // Set timer to 60 seconds
      });
  }

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (sent && !user?.isEmailVerified) {
      interval = setInterval(() => {
        refetch();
      }, 5000); // Poll every 5 seconds
    }
    return () => clearInterval(interval);
  }, [sent, user?.isEmailVerified, refetch]);

  const getButtonText = () => {
    if (sending) return 'Sending...';
    if (sent && timer > 0) return `Resend in ${timer}s`;
    if (sent && timer === 0) return 'Resend Email Verification';
    return 'Send Verification Email';
  };

  if (user?.isEmailVerified) {
    return <Navigate to={routes.Dashboard.absolutePath} replace />;
  }

  return (
    <main>
      <Loader show={sending} />
      <Animate>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={routes.Login.absolutePath} className="text-dark">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to login
              </Card.Link>
            </p>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white border rounded border-light p-4 p-lg-5 w-lg-50 w-md-100">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Email Verification</h3>
                  </div>
                  <Form className="mt-4" onSubmit={!sending && !sent ? handleSubmit : () => {}}>
                    <Button
                      disabled={sending || (sent && timer > 0)}
                      variant="primary"
                      type="submit"
                      className="w-100"
                      data-testid="verify-email-submit"
                    >
                      {getButtonText()}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Animate>
    </main>
  );
};

export default SendVerificationEmail;
