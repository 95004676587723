import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Animate from '../../common/components/Animate';

const Home = () => {
  return (
    <Animate>
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h1 className="display-4">Michael Mac Lean</h1>
            <p className="lead">Full Stack Developer</p>
            <p>
              Hi, I&apos;m Michael Mac Lean, a passionate software developer with experience in building web applications
              using modern technologies like React, Redux, Node.js, and more. I love creating beautiful and functional user
              interfaces and solving complex problems.
            </p>
            <Button variant="primary" href="#contact" className="me-2">
              Contact Me
            </Button>
            <Button variant="outline-secondary" href="https://github.com/michaelmaclean" target="_blank" className="me-2">
              <FontAwesomeIcon icon={faGithub} /> GitHub
            </Button>
            <Button variant="outline-primary" href="https://www.linkedin.com/in/michaelmaclean" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2>Skills</h2>
            <Row>
              <Col md={4}>
                <Card className="mb-4 skill-card">
                  <Card.Body>
                    <Card.Title>Frontend</Card.Title>
                    <Card.Text>React, Redux, JavaScript, TypeScript, HTML, CSS, Bootstrap</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="mb-4 skill-card">
                  <Card.Body>
                    <Card.Title>Backend</Card.Title>
                    <Card.Text>Node.js, Express, MongoDB, SQL, REST APIs, GraphQL</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="mb-4 skill-card">
                  <Card.Body>
                    <Card.Title>Tools</Card.Title>
                    <Card.Text>Git, Docker, Jenkins, AWS, VS Code, Postman</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2>Projects</h2>
            <Row>
              <Col md={6}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Project One</Card.Title>
                    <Card.Text>
                      A brief description of the project, what technologies were used, and what the project accomplished.
                    </Card.Text>
                    <Button variant="primary" href="https://github.com/michaelmaclean/project-one" target="_blank">
                      <FontAwesomeIcon icon={faGithub} /> View on GitHub
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>Project Two</Card.Title>
                    <Card.Text>
                      A brief description of the project, what technologies were used, and what the project accomplished.
                    </Card.Text>
                    <Button variant="primary" href="https://github.com/michaelmaclean/project-two" target="_blank">
                      <FontAwesomeIcon icon={faGithub} /> View on GitHub
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5" id="contact">
          <Col>
            <h2>Contact</h2>
            <p>If you&apos;d like to get in touch, feel free to reach out via email or connect with me on LinkedIn.</p>
            <p>
              <Button variant="outline-primary" href="mailto:michaelmaclean@example.com">
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </Button>{' '}
              <Button variant="outline-primary" href="https://www.linkedin.com/in/michaelmaclean" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </Animate>
  );
};

export default Home;
